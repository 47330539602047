'use client';

import * as React from 'react';
import { styled } from '@linaria/react';

import { sampleOne } from '@/utils';

import affirmations from './affirmations';

function Affirmation(props: React.HTMLAttributes<HTMLDivElement>) {
  const [affirmation, setAffirmation] = React.useState<string>('');

  React.useEffect(() => {
    // Get teh current month
    const currentMonth = new Date().getMonth();
    const isPrideMonth = currentMonth === 4;

    let selectedAffirmation;

    if (isPrideMonth) {
      // During Pride month, we’ll wish the user a happy Pride half of the time.
      selectedAffirmation =
        Math.random() < 0.5
          ? 'Happy Pride month! 🏳️‍🌈'
          : sampleOne(affirmations);
    } else {
      selectedAffirmation = sampleOne(affirmations);
    }

    if (typeof selectedAffirmation === 'function') {
      setAffirmation(selectedAffirmation());
    } else {
      setAffirmation(selectedAffirmation);
    }
  }, []);

  return <Wrapper {...props}>{affirmation}</Wrapper>;
}

const Wrapper = styled.div`
  /* Prevent layout shift */
  min-height: 1.3125rem;
  font-weight: var(--font-weight-medium);
`;

export default Affirmation;
