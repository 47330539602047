import { getTimeOfDay } from '@/utils';

export default [
  '🏳️‍⚧️ Trans rights are human rights.',
  'Have a lovely day. ❤️',
  'I hope you’re having a wonderful day!',
  'I hope you’re having a stellar day!',
  'I hope you’re having a marvelous day!',
  'I hope you’re having a sensational day!',
  'Be kind, rewind.',
  'An apple a day keeps the doctor away. 🍎',
  'Every day is an adventure. ✨',
  'You belong in this world. 💖',
  'You are an unstoppable force of nature. 🌪️',
  'Be as kind to yourself as you are to others.',
  'The world needs more kindness.',
  'You miss 100% of the shots you don’t take. 🏀',
  'You are a rockstar. 🌟',
  'Thanks for reading. 💖',
  'Hey! What a wonderful kind of day.',
  'Move it, football head!',
  'This blog kills fascists.',
  'Black lives matter.',
  'You’re like sunshine on a rainy day. 🌈',
  '👀 Be intentional with your attention.',
  'Never stop learning. 📚',
  'Cowabunga!',
  'Alphanumeric!',
  function timeAppropriateGreeting() {
    const timeOfDay = getTimeOfDay();

    switch (timeOfDay) {
      case 'morning':
        return '🥱 Good morning!';
      case 'afternoon':
        return 'Hope you’re having a lovely afternoon!';
      case 'evening':
      case 'night':
        return 'Good evening! 🌙';
      default:
        // Should be impossible
        return 'Good day! 🌞';
    }
  },
  'I’m an oddity, and that’s alright.',
  'Dream on, little dreamer. ❤️',
  'Keep your ideals high. The sky belongs to no one. ✨',
  'Let the waves of time and space surround me',
];
